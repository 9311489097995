.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}

.list-group-item {
  border-color: #f4f4f4 !important;
  border-bottom: 0;
}
.list-group-item:first-child {
  border-top: 0;
}
.list-group {
  margin-bottom: 0;
}
.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.loadingWrap .fa-spin {
  margin-right: 10px;
}
.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
.dataTables_filter {
  text-align: right;
}
.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0 !important;
  border: 0 !important;
}
.ReactModal__Content.ReactModal__Content--after-open .box-header.with-border {
  border-top: 3px solid #3c8dbc;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 9999;
}
.ReactModal__Content.ReactModal__Content--after-open table th {
  padding: 10px 15px;
  border-bottom: 1px solid #f4f4f4;
}
.ReactModal__Content.ReactModal__Content--after-open table td {
  padding: 8px 15px;
  border: 1px solid #f4f4f4;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.loadingWrap .fa-spin {
  margin-right: 10px;
}
.react-fancybox {
  display: inline-block;
  margin-left: 5px;
}
.no-pagination .dataTables_info,
.no-pagination .dataTables_paginate {
  display: none !important;
}
body .react-datepicker__input-container input.filterDate {
  width: auto !important;
  height: 30px;
  margin-right: 5px;
}
.rangeFilterCus {
  display: flex;
  align-items: center;
}

.omcoId {
  display: flex;
  align-items: center;
}
.omcoId span {
  width: 85px;
}

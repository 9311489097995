body {
  background-color: #ecf0f5;
}
.icheckbox_square-blue {
  osition: relative;
}
.icheckbox_square-blue input {
  position: absolute;
  top: -20%;
  left: -20%;
  display: block;
  width: 140%;
  height: 140%;
  margin: 0px;
  padding: 0px;
  background: rgb(255, 255, 255);
  border: 0px;
  opacity: 0;
}
.iCheck-helper {
  position: absolute;
  top: -20%;
  left: -20%;
  display: block;
  width: 140%;
  height: 140%;
  margin: 0px;
  padding: 0px;
  background: rgb(255, 255, 255);
  border: 0px;
  opacity: 0;
}
h2.login-box-msg {
  padding-left: 0;
  text-align: left;
}
.has-error.help-block {
  color: #dd4b39;
}
img.logo {
  width: 200px;
}

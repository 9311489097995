.svd_container
  .svd_content
  .svd_survey_designer
  .svd_editors
  .svd_questions_editor
  .panel-body
  .svd_question,
.svd_container
  .svd_content
  .svd_survey_designer
  .svd_editors
  .svd_questions_editor
  .card-block
  .svd_question {
  box-shadow: none;
  border-radius: 0;
  padding: 20px 9px;
  margin-bottom: 38px;
  background-color: ##fbf7f7;
  margin-top: 15px;
  border: 1px solid #ccc;
}

.sv_main {
  background-color: #ffffff;
}

.quesList {
  background-color: #f8f8f8;

  border-radius: 5px;
  overflow: hidden;
}
.quesList ul {
  padding-left: 0;
  overflow-y: scroll;
  height: 500px;
}
.quesList li {
  list-style: none;
  border-bottom: 1px solid #e4e4e4;
}
.quesList li a {
  text-decoration: none !important;
  padding: 15px;
  display: block;
}
.quesList li a:hover {
  background-color: #dedcdc;
}
.quesList h3 {
  margin: 0;
  padding: 15px;
  background-color: #ccc;
}
.quesList p {
  margin-bottom: 0;
  color: #000;
}
.quesList h4 {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #000;
}
.questionShown {
  margin-top: 30px;
}

.mainSelectedArea {
  background-color: #e8e7e7;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
}
.selected_question {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.selected_question:last-child {
  margin-bottom: 0;
}
.quest {
  display: flex;
  align-items: center;
}
.quest h4 {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #000;
}
.quest p {
  margin-bottom: 0;
  color: #000;
}
.selectQestOpt {
  margin-right: 10px;
}
.selected_question {
  position: relative;
}
.closeQuest {
  position: absolute;
  right: 5px;
  top: 0;
  font-weight: 900;
  color: #ccc;
}
.thumnailImg {
  width: 50px;
}
.imgField {
  display: inline-block !important;
}
.sponsoredReq img[data-image="null"] {
  display: none;
}

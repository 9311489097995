.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li.treeview.menu-open ul.treeview-menu {
  display: block !important;
  height: auto !important;
}
ul.treeview-menu {
  transition: all 0.5s ease !important;
}
section.sidebar {
  padding-bottom: 0 !important;
}
.sidebar-menu {
  overflow-y: scroll !important;
  height: calc(100vh - 50px);
}

.sidebar-menu::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}
.sidebar-menu::-webkit-scrollbar-track {
  background-color: transparent;
}
.sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0;
  border: 4px solid transparent;
}
.sidebar-menu::-webkit-scrollbar-button {
  display: none;
}

/* .sidebar-menu .treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
} */

ul.treeview-menu,
.p0 {
  padding: 0 !important;
}
ul.treeview-menu ul {
  padding: 15px 0px 15px 0;
}
ul.treeview-menu ul li {
  list-style: none;
}
ul.treeview-menu ul li a {
  padding: 5px 5px 5px 30px;
  display: block;
}
ul.treeview-menu ul li.active a {
  color: #fff !important;
}

.tableFooter,
.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.showEntries,
.cusSearch {
  display: flex;
  align-items: center;
}
.cusSearchBtn {
  height: 30px;
  padding: 0 10px !important;
}

.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(90deg);
}

.info-box-custom {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #ccc;
}
.info-box-content-custom {
  padding: 5px 10px;
}
.info-box-text-custom {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-box-number-custom {
  display: block;
  font-weight: 600;
  font-size: 30px;
  margin: 0 0 6px;
}
.info-box-omr-custom {
  margin: 0 0;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0;
}

.scrollTable {
  overflow: scroll;
  width: 1100px !important;
}
.table > tbody > tr > td {
  vertical-align: middle !important;
}
.rating {
  display: block;
  width: 150px;
}
a.removeQuet {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #ccc;
  text-decoration: none;
}
.field_block {
  position: relative;
}
.form-group-custom {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.w-42 {
  width: 42% !important;
}
.w-45 {
  width: 45% !important;
}
.w-85 {
  width: 85% !important;
}
.bdr0 {
  border: none;
}
.cusInput {
  width: 100%;
}
.cusInput:focus {
  outline: none;
}
ul.disNone {
  display: none;
}
.chip {
  position: initial !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  background-color: #0096fb !important;
  border: 1px solid #0096fb !important;
  color: #fff !important;
  padding: 2px 5px !important;
}
.cus-modal-dialog {
  width: 400px !important;
}
.cusModalStyle {
  text-align: center;
  padding: 30px;
  border-top: 3px solid #3c8dbc !important;
}
.cusModalStyle {
  margin-top: 0;
  width: 400px;
}
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.simpleTable {
  margin-bottom: 40px;
}

.simpleTable table {
  width: 100%;
  border: 2px solid #000;
}
.simpleTable table th,
.simpleTable table td {
  width: 85%;
  border: 1px solid;
  padding: 5px 10px !important;
}

.simpleTable table thead {
  background-color: #3c8dbc;
  color: #fff;
}
body .comment_field {
  display: none;
}
body .comment_field textarea {
  display: block;
  width: 100%;
  border: none;
}
#comment_field textarea {
  width: 100%;
  border: none;
}
#comment_field textarea:focus {
  border: 1px solid #ddd !important;
  outline: 0;
}
table.table.no-border-cus tr td {
  border: none;
  border-bottom: 1px solid #fbfbfb;
}
table.table.no-border-cus tr:last-child td {
  border: none;
}
.element_list_cus li a svg {
  width: 13%;
}

/* @media screen and (max-width: 766px) {
  .tableFooter,
  .tableHeader,
  .cusSearch {
    display: block;
  }
  .showEntries,
  .cusSearch {
    margin-bottom: 15px;
  }
  select.input-sm,
  .cusSearch input[type="search"] {
    margin-bottom: 5px;
    margin-left: 0 !important;
  }
  .tableFooter,
  .tableFooter div:last-child {
    text-align: center !important;
  }
} */
.ReactModalPortal .table-responsive {
  max-height: 500px;
}

ul#list {
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  margin-top: 10px;
}
ul#list li {
  list-style: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.event_lock{
  pointer-events: none;
}
.event_lock div{
  background-color: #ddd;
}

.react-datepicker__time-container, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px !important;
}

/* Margin */
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}

table.table th.sortable {
  color: #333;
}
.box-header.box-table-cus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-header.box-table-cus .box-tools {
  position: initial;
}
.box-table-cus.box-header:before,
.box-table-cus.box-header:after {
  content: " ";
  display: none;
}
.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.loadingWrap .fa-spin {
  margin-right: 10px;
}

.list-group-item {
  border-color: #f4f4f4 !important;
  border-bottom: 0;
}
.list-group-item:first-child {
  border-top: 0;
}
.list-group {
  margin-bottom: 0;
}
.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.loadingWrap .fa-spin {
  margin-right: 10px;
}
.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
.dataTables_filter {
  text-align: right;
}

.icon-filled {
  background: green;
  border-radius: 50%;
}
.icon-spacing i {
  margin: 2px;
}

.no-pagination .dataTables_info,
.no-pagination .dataTables_paginate,
.no-pagination .dataTables_length,
.no-pagination .dataTables_filter {
  display: none !important;
}

ul.flitersList {
  padding-left: 15px;
  line-height: 20px;
}
ul.flitersList li {
  list-style: none;
  text-transform: capitalize;
}
ul.flitersList ul {
  padding-left: 20px;
  margin-bottom: 10px;
}
ul.flitersList ul li {
  line-height: 23px;
}
ul.flitersList li a {
  margin-left: 5px;
}

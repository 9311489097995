.list-group-item {
  border-color: #f4f4f4 !important;
  border-bottom: 0;
}
.list-group-item:first-child {
  border-top: 0;
}
.list-group {
  margin-bottom: 0;
}
.map-spacing {
  position: relative;
}
.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.loadingWrap .fa-spin {
  margin-right: 10px;
}
table.station-service tbody tr td div {
  width: 30px;
  height: 30px;
}
table.station-service tbody tr td div img {
  width: 100%;
}

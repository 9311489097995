.multiSelect {
  margin: 0;
  position: relative;
}
.multiSelect input.input-box {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  margin: 0;
  border-radius: 0;
}
.chip {
  position: absolute;
  top: 4px;
  left: 6px;
}
.chip-body {
  margin-bottom: 0;
  margin-left: 0;
  padding: 3px 7px;
  margin-right: 5px;
  height: auto;
}
.checkmark {
  height: 15px;
  width: 15px;
}
.list-result {
  margin-bottom: 8px;
  font-size: 14px;
}

/* Date Range */

body .react-datepicker-wrapper {
  display: block;
}
body .react-datepicker__input-container {
  display: block;
}
body .react-datepicker__input-container input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
body .react-datepicker__day-name,
body .react-datepicker__day,
body .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 3rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 0.166rem;
}
body .react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
body .react-datepicker__current-month,
body .react-datepicker-time__header,
body .react-datepicker-year-header {
  font-size: 1.3rem;
}

.rangeFilter label {
  display: block;
}
.rangeFilter .react-datepicker-wrapper {
  display: inline-block;
  width: 49%;
}
.cusSelect {
  margin-bottom: 15px;
}
.cusSelect-14 {
  margin-bottom: 14px;
}

.cusSelect label.control-label.col-sm-2 {
  display: block !important;
  width: 100%;
  padding: 0;
}
.cusSelect .col-sm-10 {
  display: block;
  width: 100%;
  padding: 0;
}
.noRecord {
  text-align: center;
  padding: 15px 0 !important;
}

.react-confirm-alert-body {
  border-radius: 3px !important;
  border-top: 3px solid #3c8dbc !important;
  text-align: center;
}
.react-confirm-alert-button-group > button {
  background-color: #3c8dbc;
  border-color: #367fa9;
  border-radius: 3px;
}
.react-confirm-alert-overlay {
  z-index: 999;
  background: rgba(0, 0, 0, 0.74);
}
.react-confirm-alert-body > h1 {
  color: #000 !important;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
}
